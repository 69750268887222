import store from "@storefront/core/store";
import home from "@/esf_weert_mobilesupplies/store/modules/home";
import menu from "@/esf_weert_mobilesupplies/store/modules/menu";
import prod from "@/esf_weert_mobilesupplies/store/modules/product";
import wishlist from "@/esf_weert_mobilesupplies/store/modules/wishlist";
import blog from "@/esf_weert_mobilesupplies/store/modules/blog";
import sliders from "@/esf_weert_mobilesupplies/store/modules/slider";
import checkout from "@/esf_weert_mobilesupplies/store/modules/checkout";
import rma from "@/esf_weert_mobilesupplies/store/modules/rma";
import user from "@/esf_weert_mobilesupplies/store/modules/user";

store.registerModule("home", home);
store.registerModule("menu", menu);
store.registerModule("productSerie", prod);
store.registerModule("wishlist", wishlist);
store.registerModule("blog_additional", blog);
store.registerModule("categorySlider", sliders);
store.registerModule("checkout", checkout);
store.registerModule("rma", rma);
store.registerModule("user2", user);

export default store;
