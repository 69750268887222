/*eslint no-unused-vars: ["error", { "args": "none" }]*/
import {
  getProductByUrl,
  getProductRelated,
  getProductDetails,
  getProductCrossSell,
  getCartCrossSell,
  getProductUpSell,
  getProductBySku,
  getCartRelated
} from "@storefront/core/data-resolver/products";
import {
  getBrandSlider,
  getBrandDetail,
  getProductByBrand,
  getAggregationsByBrand
} from "@storefront/core/data-resolver/products";
import { productReviewRatingsMetadata } from "@storefront/core/data-resolver/products";
import { Logger } from "@storefront/core/lib/logger";
import store from "@/store";
import config from "@config";

const state = () => ( {
  current: null, // shown product
  currentGallery: [],
  currentChildSku: null,
  currentOptions: [],
  currentBundle: [],
  selectedBundles: [],
  currentGrouped: [],
  parent: null,
  brands: [],
  related: [],
  crossSell: [],
  upsell: [],
  ProductReviewRatingsMetadata: [],
  compare: [],
  brandProducts: [],
  currentPage: 1,
  brandDetailData: {},
  brandPageInfo: {},
  brandProductsTotalCount: 0,
  sorting: "default",
  currentSorting: null,
  prodsPerPage: 15,
  aggregations: [],
  productFilters: [],
  priceFilter: [0, 0],
  priceFilterMin: 0,
  priceFilterMax: 0,
  activeFilter: [],
} );

const actions = {
  /**
   * load product
   *
   * @param {object} string path
   * @returns product
   */
  async load( { commit, dispatch }, { path = "" } ) {
    const prodUrl = path.replace( ".html", "" );
    let urlKey = prodUrl.split( "/" ).pop();
    if ( urlKey == "" ) {
      urlKey = prodUrl.split( "/" )[ 0 ];
    }
    if ( typeof urlKey == "string" ) {
      const products = await getProductByUrl( urlKey ).catch( ( e ) => {
        Logger.error( "getProductByUrl", "product store actions load", e )();
        throw e;
      } );
      const product = products.items[ 0 ];
      if ( product == null ) {
        return false;
      } else {
        commit( "setCurrentProduct", product );
        dispatch( "loadRelated", { sku: product.sku } );
        dispatch( "loadCrossSell", { sku: product.sku } );
        dispatch( "loadUpSell", { sku: product.sku } );
        if ( product.__typename == "ConfigurableProduct" ) {
          dispatch( "loadConfigProduct", {
            sku: product.sku,
            type: product.__typename,
          } );
        }
        if ( product.__typename == "BundleProduct" ) {
          dispatch( "loadBundleProduct", {
            sku: product.sku,
            type: product.__typename,
          } );
        }
        if ( product.__typename == "GroupedProduct" ) {
          dispatch( "loadGroupedProduct", {
            sku: product.sku,
            type: product.__typename,
          } );
        }

        const breadcrumbs = [];
        // if (product.categories.length > 0) {
        //   const curCat = {
        //     category_name: product.categories[0].name,
        //     category_level: product.categories[0].level,
        //     category_url_key: product.categories[0].url_key,
        //   };
        //   breadcrumbs.push(curCat);
        //   if (product.categories[0].breadcrumbs != null) {
        //     product.categories[0].breadcrumbs.forEach((element) => {
        //       breadcrumbs.push(element);
        //     });
        //   }
        // }
        let currentName = "undefined";
        if ( typeof product.name == "string" ) {
          currentName = product.name;
        }
        const bcrumb = { current: currentName, routes: [] };
        if ( breadcrumbs != null ) {
          breadcrumbs.sort( ( a, b ) => {
            if ( a.category_level < b.category_level ) {
              return -1;
            }
            if ( a.category_level > b.category_level ) {
              return 1;
            }
            return 0;
          } );
          let path = "";
          breadcrumbs.forEach( ( element ) => {
            if ( path.length > 0 ) {
              path = path + "/";
            }
            path = path + element.category_url_key;
            let name = "undefined";
            if ( typeof element.category_name == "string" ) {
              name = element.category_name;
            }
            const bc = {
              name: name,
              route_link: path,
            };
            bcrumb.routes.push( bc );
          } );
        }
        store.commit( "breadcrumbs/set", bcrumb );
        return product;
      }
    }

    return false;
  },

  /**
   * load product
   *
   * @param {object} string path
   * @returns product
   */
  async loadWithSku( { commit, dispatch }, { sku = "" } ) {
    const products = await getProductBySku( sku ).catch( ( e ) => {
      Logger.error( "getProductBySku", "product store actions setProduct", e )();
      throw e;
    } );
    const product = products.items[ 0 ];
    if ( product == null ) {
      return false;
    } else {
      commit( "setCurrentProduct", product );
      dispatch( "loadRelated", { sku: product.sku } );
      dispatch( "loadCrossSell", { sku: product.sku } );
      dispatch( "loadUpSell", { sku: product.sku } );
      if ( product.__typename == "ConfigurableProduct" ) {
        dispatch( "loadConfigProduct", {
          sku: product.sku,
          type: product.__typename,
        } );
      }
      if ( product.__typename == "BundleProduct" ) {
        dispatch( "loadBundleProduct", {
          sku: product.sku,
          type: product.__typename,
        } );
      }
      if ( product.__typename == "GroupedProduct" ) {
        dispatch( "loadGroupedProduct", {
          sku: product.sku,
          type: product.__typename,
        } );
      }
      const breadcrumbs = [];
      let currentName = "undefined";
      if ( typeof product.name == "string" ) {
        currentName = product.name;
      }
      const bcrumb = { current: currentName, routes: [] };
      if ( breadcrumbs != null ) {
        breadcrumbs.sort( ( a, b ) => {
          if ( a.category_level < b.category_level ) {
            return -1;
          }
          if ( a.category_level > b.category_level ) {
            return 1;
          }
          return 0;
        } );
        let path = "";
        breadcrumbs.forEach( ( element ) => {
          if ( path.length > 0 ) {
            path = path + "/";
          }
          path = path + element.category_url_key;
          let name = "undefined";
          if ( typeof element.category_name == "string" ) {
            name = element.category_name;
          }
          const bc = {
            name: name,
            route_link: path,
          };
          bcrumb.routes.push( bc );
        } );
      }
      store.commit( "breadcrumbs/set", bcrumb );
      return product;
    }

    //return false;
  },

  /**
   * load related products
   *
   * @param {object} string sku
   */
  async loadRelated({ commit }, { sku }) {
    const products = await getProductRelated(sku).catch((e) => {
      Logger.error("getProductRelated", "product store actions load", e)();
      throw e;
    });
    const product = products.items[0];
    commit("setRelated", product.related_products);
  },

  loadCompareProducts({ commit }) {
    const compareProducts =
      JSON.parse(localStorage.getItem("compareProducts")) || [];
    commit("initializeCompareProducts", compareProducts);
  },

  async addCompareProduct({ commit }, { sku }) {
    try {
      const products = await getProductBySku(sku);
      const product = products.items[0];

      // Store product in local storage
      let compareProducts =
        JSON.parse(localStorage.getItem("compareProducts")) || [];
      compareProducts.push(product);
      localStorage.setItem("compareProducts", JSON.stringify(compareProducts));

      commit("setProductToCompare", product);
    } catch (e) {
      Logger.error("addCompareProduct", "product store actions load", e)();
      throw e;
    }
  },
  async removeCompareProduct({ commit }, { sku }) {
    // Remove product from Vuex state
    commit("removeProductFromCompare", sku);

    // Update the local storage
    let compareProducts =
      JSON.parse(localStorage.getItem("compareProducts")) || [];
    compareProducts = compareProducts.filter((product) => product.sku !== sku);
    localStorage.setItem("compareProducts", JSON.stringify(compareProducts));
  },
  async loadCrossSell({ commit }, { sku }) {
    const products = await getProductCrossSell(sku).catch((e) => {
      Logger.error("getProductCrossSell", "product store actions load", e)();
      throw e;
    });
    const product = products.items[0];
    if (product.crosssell_products) {
      commit("setCrossSell", product.crosssell_products);
    }
  },
  async loadCrossSellForCart({ commit }, { skus }) {
    const products = await getCartCrossSell(skus).catch((e) => {
      Logger.error("loadCrossSellForCart", "product store actions load", e)();
      throw e;
    });
    if (products) {
      commit("setCrossSell", products);
    }
  },
  async loadRelatedForCart({ commit }, { skus }) {
    const products = await getCartRelated(skus).catch((e) => {
      Logger.error("loadRelatedForCart", "product store actions load", e)();
      throw e;
    });
    if (products) {
      commit("setRelated", products);
    }
  },
  async loadUpSell({ commit }, { sku }) {
    const products = await getProductUpSell(sku).catch((e) => {
      Logger.error("getProductUpSell", "product store actions load", e)();
      throw e;
    });
    const product = products.items[0];
    commit("setUpSell", product.upsell_products);
  },
  async loadConfigProduct({ commit }, { sku, type }) {
    const products = await getProductDetails(sku, type).catch((e) => {
      Logger.error("getProductDetails", "product store actions load", e)();
      throw e;
    });
    const product = products.items[0];
    commit("setCurrentConfig", product);

    const prodOptions = [];
    if (product.configurable_options.length == 1) {
      const values = [];
      product.variants.forEach((element) => {
        const value = {
          label: element.attributes[0].label,
          value_index: element.product.sku,
          price: element.product.price_range.minimum_price.final_price.value.toFixed(
            2
          ),
        };
        values.push( value );
      } );
      const prodOption = {
        index: 0,
        attribute_code: product.configurable_options[ 0 ].attribute_code,
        label: product.configurable_options[ 0 ].label,
        id: product.configurable_options[ 0 ].id,
        choice: null,
        values: values,
      };
      prodOptions.push( prodOption );
    } else {
      product.configurable_options.forEach( ( option, index ) => {
        if ( index == 0 ) {
          const prodOption = {
            index: index,
            attribute_code: option.attribute_code,
            label: option.label,
            id: option.id,
            choice: null,
            values: option.values,
          };
          prodOptions.push( prodOption );
        } else {
          const prodOption = {
            index: index,
            attribute_code: option.attribute_code,
            label: option.label,
            choice: null,
            id: option.id,
            values: [],
          };
          prodOptions.push( prodOption );
        }
      } );
    }
    commit( "setCurrentOptions", prodOptions );
    commit( "setCurrentChildSku", null );
  },
  async loadBundleProduct( { commit }, { sku, type } ) {
    const products = await getProductDetails( sku, "BundleProduct" ).catch(
      ( e ) => {
        Logger.error( "getProductDetails", "product store actions load", e )();
        throw e;
      }
    );

    const bundleOptions = products.items[ 0 ].items;

    commit( "setCurrentBundle", bundleOptions );

    const selectedBundles = [];

    bundleOptions.forEach( ( bundles, index ) => {
      const bundleID = bundles.option_id;
      const selectedOption = bundles.options[ 0 ];
      const value = {
        bundle_id: bundleID,
        option_selection_id: selectedOption.id,
        quantity: selectedOption.quantity,
      };
      selectedBundles[ index ] = value;
    } );
    commit( "setSelectedBundles", selectedBundles );
  },
  async loadGroupedProduct( { commit }, { sku, type } ) {
    const products = await getProductDetails( sku, type ).catch( ( e ) => {
      Logger.error( "getProductDetails", "product store actions load", e )();
      throw e;
    } );
    const product = products.items[ 0 ].items;
    commit( "setCurrentGrouped", product );
  },

  /**
   * Load the brand slider
   *
   * @returns object or false
   */
  async loadBrandSlider({ commit }) {
    const brandsliders = await getBrandSlider().catch((e) => {
      Logger.error("getBrandSlider", "product store actions load", e)();
      throw e;
    });

    if (brandsliders.items[0] == null) {
      return false;
    } else {
      commit("setBrandslider", brandsliders.items);

      return brandsliders.items[0];
    }
  },
  /**
   * get the brand details
   *
   * @returns object or false
   */
  async getBrandDetail({ commit }, { urlKey }) {
    const brandDetail = await getBrandDetail(
      urlKey, 
    ).catch((e) => {
      Logger.error( "getBrandDetail", "product store actions load", e )();
      throw e;
    });
    
    if (brandDetail) {
      commit("setBrandDetail", brandDetail[0]);
      return true;
    } else {
      return false;
    }
  },

  /**
   * get products for brand details
   *
   * @returns object or false
   */
  async getProductByBrand({ commit, getters, dispatch }) {
    try {
      const brandDetail = getters['getBrandDetailData'];
      const currPage = getters.getBrandDetailCurrentPage || 1;
      const sort = getters["getCurrentSorting"];
      const perPage = getters["getProductsPerPage"];
      const isPriceFilterActive = getters["getIsPriceFilterActive"];
      const filters = [];

      if (isPriceFilterActive) {
        const priceFilter = getters["getPriceFilter"];
        filters.push({ attribute_code: "price", values: priceFilter });
      }

      const activeFilters = getters["getActiveFilter"];
      if(activeFilters){
        activeFilters.forEach((el) => {
          const index = filters.findIndex(
            (o) => o.attribute_code == el.attribute_code
          );

          if (index != -1) {
            filters[index].values.push(el.value);
          } else {
            filters.push({ attribute_code: el.attribute_code, values: [el.value] });
          }
        });
      }

      // Set the default filters for the brand detail page
      if(activeFilters.length <= 0) {
        await dispatch("loadAggregations");
        const aggregations = await getters['getAggregations'];

        if (aggregations && aggregations.length) {
          aggregations.forEach((element) => {
            if (element.attribute_code != "category_id" && element.attribute_code != "category_uid") {
              if (element.attribute_code == "price") {
                if (parseInt(element.min_value) < 0) {
                  element.min_value = 0;
                }
                element.min = parseInt(element.min_value);
                if (Math.ceil(element.max_value) < element.min_value) {
                  element.max = element.min_value;
                } else {
                  element.max = Math.ceil(element.max_value);
                }
                if (element.min < 0) {
                  element.min = 0;
                }
                if (element.max < 0) {
                  element.max = 0;
                }

                const priceFilter = getters["getPriceFilter"];
                if (priceFilter[0] == 0 && priceFilter[1] === 0) {
                  commit("setPriceFilterMin", element.min);
                  commit("setPriceFilterMax", element.max);
                  commit("addPriceFilter", [element.min, element.max]);
                }
              }
            }
          });
        }
      }

      // Call the API to get the data for brand detail page (default and updated both)
      if (brandDetail && currPage) { 
        const retval = await getProductByBrand(
          brandDetail.attributeValue,
          currPage,
          perPage,
          {
            sort, filters
          }
        ).catch((e) => {
          Logger.error(
            "getProductFiltersByFilteredCategory",
            "category store actions load",
            e
          )();
          throw e;
        });

        // Update Data
        if (retval) {
          // Set new aggregations depending upon active filters
          const aggregations = retval.data.data.products.aggregations;
          if (aggregations && aggregations.length) {
            // Set price
            if(!isPriceFilterActive) {
              aggregations.forEach((element) => {
                if (element.attribute_code == "price") {
                  if (parseInt(element.min_value) < 0) {
                    element.min_value = 0;
                  }
                  element.min = parseInt(element.min_value);
                  if (Math.ceil(element.max_value) < element.min_value) {
                    element.max = element.min_value;
                  } else {
                    element.max = Math.ceil(element.max_value);
                  }
                  if (element.min < 0) {
                    element.min = 0;
                  }
                  if (element.max < 0) {
                    element.max = 0;
                  }

                  commit("setPriceFilterMin", element.min);
                  commit("setPriceFilterMax", element.max);
                  commit("addPriceFilter", [element.min, element.max]);
                }
              });
            }
            
            commit("setAggregations", aggregations);
            commit("setProductFilters", aggregations);
          }

          // Set new prodcuts
          const products = retval.data.data.products || [];
          commit("setBrandProducts", products);
        } else {
          return false;
        }
      } else {
        commit("resetBrandProducts");
        return false;
      }
      
    } catch (error) {
      console.error("Error fetching brand products:", error);
      return false;
    }
  },

  // Update the pagination
  async updateCurrentPage({ commit, getters, dispatch }, pageNo) {
    commit("setCurrentPage", pageNo);
    const brandDetail = getters['getBrandDetailData'];
    
    if (brandDetail && brandDetail.attributeValue) {
      dispatch("getProductByBrand", { attributeValue: brandDetail.attributeValue });
    } else {
      console.warn("No brand detail available or attributeValue is missing");
    }
  },

  /**
   * Loads aggregations for brand filtering
   * @param {object}
   * @param {object} integer brand_id
   */
  async loadAggregations({ commit, getters }) {
    const brandDetail = getters['getBrandDetailData'];

    if (brandDetail) {
      var aggregations = await getAggregationsByBrand(brandDetail.attributeValue);
      commit("setAggregations", aggregations);
      commit("setProductFilters", aggregations);
    } else {
      return false;
    }
  },

  /**
   * Load product review ratings meta data
   *
   * @returns object or false
   */
  async productReviewRatingsMetadata( { commit } ) {
    const metaData = await productReviewRatingsMetadata().catch( ( e ) => {
      Logger.error( "getBrandSlider", "product store actions load", e )();
      throw e;
    } );
    if ( metaData.items.length == 0 ) {
      return false;
    } else {
      commit( "setProductReviewRatingsMetadata", metaData.items );

      return metaData.items;
    }
  },
};

const mutations = {
  initializeCompareProducts(state, products) {
    state.compare = products;
  },
  setProductToCompare(state, data) {
    state.compare.push(data);
  },
  removeProductFromCompare(state, sku) {
    state.compare.forEach((prod, index) => {
      if (prod.sku == sku) {
        state.compare.splice(index, 1);
      }
    });
  },
  /**
   * Set current Product
   *
   * @param {object} data
   * @private
   */
  setCurrentProduct( state, data ) {
    state.current = data;
  },
  /**
   * set current product options
   *
   * @param {array} data
   * @private
   */
  setCurrentOptions( state, data ) {
    state.currentOptions = data;
  },
  /**
   * Set current Child SKU
   *
   * @param {string} data
   */
  setCurrentChildSku( state, data ) {
    state.currentChildSku = data;
  },
  /**
   * Set product review rating Meta data
   *
   * @param {array} data
   */
  setProductReviewRatingsMetadata(state, data) {
    state.ProductReviewRatingsMetadata = data;
  },
  setOptionValue( state, data ) {
    for ( let i = data.index + 1; i < state.currentOptions.length; i++ ) {
      state.currentOptions[ i ].choice = null;
      state.currentOptions[ i ].values = [];
    }
    if ( data.index == state.currentOptions.length - 1 ) {
      state.currentChildSku = data.value;
    } else {
      state.currentOptions[ data.index ].choice = data.value;
      state.currentChildSku = null;
      let products = state.currentConfig.variants;
      for ( let i = 0; i <= data.index; i++ ) {
        products = products.filter( function ( item ) {
          const retval = item.attributes.find( ( o ) => {
            if ( o.value_index == state.currentOptions[ i ].choice ) {
              return true;
            }
          } );
          if ( retval != null ) {
            return true;
          }
        } );
      }
      if ( data.index == state.currentOptions.length - 2 ) {
        const values = [];
        products.forEach( ( element ) => {
          const retval = element.attributes.find( ( o ) => {
            if ( o.code == state.currentOptions[ data.index + 1 ].attribute_code ) {
              return true;
            }
          } );
          const value = {
            label: retval.label,
            value_index: element.product.sku,
          };
          values.push( value );
        } );
        state.currentOptions[ data.index + 1 ].values = values;
      } else {
        const values = [];
        products.forEach( ( element ) => {
          const retval = element.attributes.find( ( o ) => {
            if ( o.code == state.currentOptions[ data.index + 1 ].attribute_code ) {
              return true;
            }
          } );
          const value = {
            label: retval.label,
            value_index: retval.value_index,
          };
          values.push( value );
        } );
        state.currentOptions[ data.index + 1 ].values = values;
      }
    }
  },
  /**
   * set the brand slider
   *
   * @param {array} data
   * @private
   */
  setBrandslider( state, data ) {
    state.brands = data;
  },
  /**
   * set related products
   *
   * @param {array} data
   * @private
   */
  setRelated( state, data ) {
    state.related = data;
  },
  /**
   * set related products
   *
   * @param {array} data
   * @private
   */
  setCrossSell( state, data ) {
    state.crossSell = data;
  },
  /**
   * set related products
   *
   * @param {array} data
   * @private
   */
  setUpSell( state, data ) {
    state.upsell = data;
  },
  /**
   * set current configuration of the configural product
   *
   * @param {object} data
   * @private
   */
  setCurrentConfig( state, data ) {
    state.currentConfig = data;
  },
  /**
   * set current bundles of the bundle product
   *
   * @param {object} data
   * @private
   */
  setCurrentBundle( state, data ) {
    state.currentBundle = data;
  },
  /**
   * set selected bundles of the bundle product
   *
   * @param {object} data
   * @private
   */
  setSelectedBundles( state, data ) {
    state.selectedBundles = data;
  },
  setBundleOptionValue( state, data ) {
    const currentIndex = data.index;

    if ( state.selectedBundles[ currentIndex ] ) {
      const selectedBundles = [ ...state.selectedBundles ];
      const newVal = {
        bundle_id: data.bundle_id,
        option_selection_id: data.value,
        quantity: data.quantity,
      };
      selectedBundles[ currentIndex ] = newVal;
      store.commit( "product/setSelectedBundles", selectedBundles );
    }
  },
  /**
   * set current grouped of the grouped product
   *
   * @param {object} data
   * @private
   */
  setCurrentGrouped( state, data ) {
    state.currentGrouped = data;
  },

  /**
   * Set the products and page info
   * 
   * @param {array, object, integer} state 
   * @param {object} data 
   */
  setBrandProducts(state, data) {
    state.brandProducts = data.items;
    state.brandPageInfo = data.page_info;
    state.brandProductsTotalCount = data.total_count;
  },

  /**
   * Reset the products and page info
   * 
   * @param {array, object, integer} state 
   */
  resetBrandProducts(state) {
    state.brandProducts = [];
    state.brandPageInfo = {};
    state.brandProductsTotalCount = 0;
  },

  /**
   * set currentPage
   *
   * @param {integer} state
   * @param {integer} data
   * @private
   */
  setCurrentPage(state, data) {
    if (data >= 1) {
      state.currentPage = data;
    }
  },

  /**
   * set brandDetailData
   *
   * @param {object} state
   * @param {object} data
   * @private
   */
  setBrandDetail(state, data) {
    state.brandDetailData = data;
  },

  /**
   * set sorting
   *
   * @param {object} state
   * @param {string} data
   */
  setSorting(state, data) {
    if (config.sortingOptions[data]) {
      state.sorting = data;
      const [sortBy, direction = "ASC"] = config.sortingOptions[data];
      let currentSorting = null;
      if (sortBy && direction) {
        currentSorting = { sortBy, direction };
      }
      store.commit("product/setCurrentSorting", currentSorting);
    }
  },

  /**
   * add current sorting
   *
   * @param {object} state
   * @param {object} data
   */
  addCurrentSorting(state, data) {
    state.currentSorting = data;
  },

  /**
   * reset sorting
   *
   * @param {object} state
   * @param {array} data
   */
  resetSorting(state) {
    state.sorting = "default";
    state.currentSorting = null;
  },

  /**
   * set current sorting
   *
   * @param {object} state
   * @param {object} data
   */
  setCurrentSorting(state, data) {
    state.currentSorting = data;
    store.commit("resetCurrentPage");
    store.dispatch("product/getProductByBrand");
  },

  resetCurrentPage(state) {
    state.currentPage = 1;
  },

  /**
   * set productPerPage
   *
   * @param {object} state
   * @param {integer} data
   * @private
   */
  setProductsPerPage(state, data) {
    state.prodsPerPage = data;
    store.commit("resetCurrentPage");

    const totalProducts = state.brandProductsTotalCount;
    const totalPages = Math.ceil(state.brandProductsTotalCount / data);
    state.totalPages = totalPages;

    if (totalProducts > 0) {
      store.dispatch("product/getProductByBrand");
    }
  },

  /**
   * 
   * @param {object} state 
   * @param {object} data 
   */
  setAggregations(state, data) {
    state.aggregations = data;
  },

  /**
   * set product filters
   *
   * @param {object} state
   * @param {object} data
   * @private
   *
   */
  setProductFilters(state, data) {
    state.productFilters = data;
  },

  /**
   * add price filter
   *
   * @param {object} state
   * @param {array} data
   */
  addPriceFilter(state, data) {
    state.priceFilter = data;
  },
  
  /**
   * set price filter
   *
   * @param {object} state
   * @param {array} data
   */
  setPriceFilter(state, data) {
    state.priceFilter = data;
    store.commit("product/resetCurrentPage");
    store.dispatch("product/getProductByBrand");
  },

  /**
   * reset price filter
   *
   * @param {object} state
   */
  resetPriceFilter(state) {
    state.priceFilter = [0, 0];
  },

  /**
   * set min price
   *
   * @param {object} state
   * @param {integer} data
   */
  setPriceFilterMin(state, data) {
    state.priceFilterMin = data;
  },

  /**
   * set max price
   *
   * @param {object} state
   * @param {integer} data
   */
  setPriceFilterMax(state, data) {
    state.priceFilterMax = data;
  },

  /**
   * remove price filter
   *
   * @param {object} state
   */
  removePriceFilter(state) {
    const priceMin = state.priceFilterMin;
    const priceMax = state.priceFilterMax;
    state.priceFilter = [priceMin, priceMax];
  },

  /**
   * add active filter
   *
   * @param {object} state
   * @param {object} data
   */

  addActiveFilter(state, data) {
    state.activeFilter = data;
  },
  /**
   * reset active filter
   *
   * @param {object} state
   */
  resetActiveFilter(state) {
    state.activeFilter = [];
  },

  /**
   * set active filter
   *
   * @param {object} state
   * @param {object} data
   */
  setActiveFilter(state, data) {
    const obj = state.activeFilter.find((o) => {
      if (o.value == data.value) {
        return true; // stop searching
      }
    });
    if (obj == null || !obj) {
      state.activeFilter.push(data);
    }
    store.commit("product/resetCurrentPage");
    store.dispatch("product/getProductByBrand");
  },

  /**
   * remove active filter
   *
   * @param {object} state
   * @param {object} data
   */
  removeActiveFilter(state, data) {
    if (state.activeFilter.length > 0) {
      state.activeFilter.find((o, i) => {
        if (o.value == data.value) {
          state.activeFilter.splice(i, 1);
          return true;
        }
      });
      store.commit("product/resetCurrentPage");
      store.dispatch("product/getProductByBrand");
    }
  },

  /**
   * remove all active filter
   *
   * @param {object} state
   */
  removeAllActiveFilter(state) {
    state.activeFilter = [];
    store.commit("product/resetCurrentPage");
    store.commit("product/removePriceFilter");
    store.dispatch("product/getProductByBrand");
  },
};

const getters = {
  getCurrentProduct: ( state ) => state.current,
  getProductCompare: ( state ) => state.compare,
  getCurrentProductConfiguration: ( state ) => state.currentConfiguration,
  getCurrentProductOptions: ( state ) => state.currentOptions,
  getProductGallery: ( state ) => state.currrentGallery,
  getBrandSlider: ( state ) => state.brands,
  getBrandSliderByCode: ( state ) => ( code ) =>
    state.brands.find(
      ( item ) => typeof item === "object" && item.urlKey === code
    ),
  getBrandProducts: (state) => state.brandProducts,
  getBrandPageInfo: (state) => state.brandPageInfo,
  getBrandProductsTotalCount: (state) => state.brandProductsTotalCount,
  getBrandDetailCurrentPage: (state) => state.currentPage,
  getBrandDetailData: (state) => state.brandDetailData,
  getSorting: (state) => state.sorting,
  getCurrentSorting: (state) => state.currentSorting,
  getProductsPerPage: (state) => state.prodsPerPage || 15,
  getAggregations: (state) => state.aggregations,
  getProductFilters: (state) => state.productFilters,
  getPriceFilterMin: (state) => state.priceFilterMin,
  getPriceFilterMax: (state) => state.priceFilterMax,
  getIsPriceFilterActive: (state) =>
    !(state.priceFilter[0] === 0 && state.priceFilter[1] === 0) &&
    !(
      state.priceFilter[0] === state.priceFilterMin &&
      state.priceFilter[1] === state.priceFilterMax
    ),
  getPriceFilter: (state) => state.priceFilter,
  getActiveFilter: (state) => state.activeFilter,

  getProductReviewRatingsMetadata: ( state ) =>
    state.ProductReviewRatingsMetadata,
  getCurrentChildSku: ( state ) => state.currentChildSku,
  getRelated: ( state ) => state.related,
  getCrossSell: ( state ) => state.crossSell,
  getUpSell: ( state ) => state.upsell,
  getCurrentConfig: ( state ) => state.currentConfig,
  getCurrentBundle: ( state ) => state.currentBundle,
  getSelectedBundles: ( state ) => state.selectedBundles,
  getSelectedBundlesOptions: ( state ) => {
    const selectedOptions = [];
    state.selectedBundles.forEach( ( element ) => {
      let selectedOption = "bundle";
      selectedOption += "/" + element.bundle_id;
      selectedOption += "/" + element.option_selection_id;
      selectedOption += "/" + element.quantity;
      selectedOption = btoa( selectedOption );
      selectedOptions.push( selectedOption );
    } );
    return selectedOptions;
  },
  getCurrentGrouped: ( state ) => state.currentGrouped,
  getGroupedTotalPrice: ( state ) => {
    let price = 0;
    if ( state.currentGrouped ) {
      state.currentGrouped.forEach( ( element ) => {
        let p =
          element.qty *
          element.product.price_range.maximum_price.final_price.value;
        price = price + p;
      } );
    }
    return price;
  },
};

// export this module.
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
