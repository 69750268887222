const Home = () => import("../core/views/HomeView.vue");
const Categories = () =>
  import("../core/views/categories/DefaultCategories.vue");
// const Shop = () => import("../core/views/shop/DefaultShop.vue");
const ProductDetails = () => import("../core/views/shop/ProductDetails.vue");
const CompareProducts = () => import("../core/views/CompareProducts.vue");
const ContactUs = () => import("../core/views/ContactUs.vue");
const CustomerService = () => import("../core/views/CustomerService.vue");
const OrderConfirmation = () => import("../core/views/OrderConfirmation.vue");
const PaymentCancel = () => import("../core/views/PaymentCancel.vue");
const ShoppingCart = () => import("../core/views/shop/ShoppingCart.vue");
const BlogsList = () => import("../core/views/blog/BlogsList.vue");
const BlogDetails = () => import("../core/views/blog/BlogDetails.vue");
const BrandsOverview = () => import("../core/views/brand/BrandsOverview.vue");
const BrandDetails = () => import("../core/views/brand/BrandDetails.vue");
const Account = () => import("../core/views/Account.vue");
const Checkout = () => import("../core/views/Checkout.vue");
const PrivacyPolicy = () => import("../core/views/PrivacyPolicy.vue");
const NotFound = () => import("../core/views/NotFound.vue");
const CmsPage = () => import("../core/views/static-page/StaticPage.vue");
const SearchPage = () => import("../core/views/search/SearchPage.vue");

// auth
const LogIn = () => import("../core/views/auth/LogIn.vue");
const CreateAccount = () => import("../core/views/auth/CreateAccount.vue");
const ForgotPassword = () => import("../core/views/auth/ForgotPassword.vue");
const ResetPassword = () => import("../core/views/auth/ResetPassword.vue");
// auth

const routes = [
  // auth
  { path: "/customer/account", redirect: "/login/" },
  { name: "login", path: "/login/", component: LogIn },
  {
    name: "create account",
    path: "/create-account/",
    component: CreateAccount,
  },
  {
    name: "forgot password",
    path: "/forgot-password/",
    component: ForgotPassword,
  },
  {
    name: "reset-password",
    path: "/customer/account/createPassword/",
    component: ResetPassword, pathToRegexpOptions: { strict: true }
  },
  // auth

  {
    name: "product-SimpleProduct",
    path: "/product/",
    component: ProductDetails,
    pathToRegexpOptions: { strict: true },
  },
  {
    name: "product-ConfigurableProduct",
    path: "/productconfig/",
    component: ProductDetails,
    pathToRegexpOptions: { strict: true },
  },
  {
    name: "product-BundleProduct",
    path: "/productbundle/",
    component: ProductDetails,
    pathToRegexpOptions: { strict: true },
  },
  {
    name: "product-page",
    path: "/product/",
    component: ProductDetails,
    pathToRegexpOptions: { strict: true },
  },
  {
    name: "product-LookProduct",
    path: "/productlook/",
    component: ProductDetails,
    pathToRegexpOptions: { strict: true },
  },
  {
    name: "home",
    path: "/",
    component: Home,
  },
  // { name: "shop", path: "/shop", component: Shop },
  { name: "brands", path: "/merken/", component: BrandsOverview },
  {
    name: "brand details",
    path: "/brand-details/:code",
    component: BrandDetails,
  },
  { name: "shopping cart", path: "/shopping-cart/", component: ShoppingCart },
  { name: "compare", path: "/compare", component: CompareProducts },
  {
    name: "order",
    path: "/order/",
    component: Checkout,
    pathToRegexpOptions: { strict: true },
  },
  // { name: "checkout", path: "/checkout/", component: Checkout },
  // {
  //   name: "order confirmation",
  //   path: "/order-confirmation/",
  //   component: OrderConfirmation,
  // },
  {
    name: "onpagesuccess",
    path: "/checkout/onepage/success/",
    component: OrderConfirmation,
    pathToRegexpOptions: { strict: true },
  },
  {
    name: "onpagecancel",
    path: "/checkout/onepage/cancel/",
    component: PaymentCancel, pathToRegexpOptions: { strict: true }
  },
  {
    name: "multisafepaysuccess",
    path: "/multisafepay/connect/success",
    component: OrderConfirmation,
    pathToRegexpOptions: { strict: true },
  },
  {
    name: "multisafepaycancel",
    path: "/multisafepay/connect/cancel",
    component: PaymentCancel, pathToRegexpOptions: { strict: true }
  },
  {
    name: "privacy policy",
    path: "/privacy-policy/",
    component: PrivacyPolicy,
  },
  {
    name: "contact",
    path: "/contact/",
    component: ContactUs,
  },
  // {
  //   name: "klantenserviceberichten",
  //   path: "/klantenservice/",
  //   component: CustomerService,
  //   pathToRegexpOptions: { strict: true },
  // },
  // {
  //   name: "klantenservice",
  //   path: "/klantenservice/:code/",
  //   component: CustomerService,
  //   pathToRegexpOptions: { strict: true },
  // },
  {
    name: "klantenservice-category",
    path: "/klantenservice/categorie/:category_id/",
    component: CustomerService,
    pathToRegexpOptions: { strict: true },
  },
  { name: "account", path: "/account/", component: Account },
  { name: "blogs", path: "/blog/", component: BlogsList },
  {
    name: "blog-category",
    path: "/blog/categorie/:category_id/",
    component: BlogsList,
  },
  { name: "blog details", path: "/blog/:id", component: BlogDetails },
  {
    name: "category-page",
    path: "/category-page/",
    component: Categories,
    pathToRegexpOptions: { strict: true },
  },
  {
    name: "search",
    path: "/search/",
    component: SearchPage,
    pathToRegexpOptions: { strict: true },
  },
  {
    name: "cms-page",
    path: "/cms-page/",
    component: CmsPage,
    pathToRegexpOptions: { strict: true },
  },
  {
    name: "page-not-found",
    path: "/page-not-found/",
    component: NotFound,
    pathToRegexpOptions: { strict: true },
  },
  { path: "/page-not-found", redirect: "/page-not-found/" },
];

export default routes;
