import axios from 'axios';
import config from '@config';
import store from "@/store";
import { getCurrentLanguage } from "@storefront/core/i18n";
import { Logger } from "@storefront/core/lib/logger";
import { getCurrent } from "@storefront/core/data-resolver/user";
import { mergeCart, getCustomerCartToken } from "@storefront/core/data-resolver/cart";

export async function createAccountV2(account) {
  const lang = getCurrentLanguage();
  const storelang = config.languages[lang];
  const storeview = storelang["storeview"];

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + config.shop.accessToken,
    Store: storeview,
  };
  if (typeof account.is_subscribed == "undefined") {
    account.is_subscribed = false;
  }

  let query = "mutation { createCustomerV2( input: {";
  if ("firstname" in account) {
    query = query + " firstname: " + JSON.stringify(account.firstname);
  }
  if ("lastname" in account) {
    query = query + " lastname: " + JSON.stringify(account.lastname);
  }
  if ("email" in account) {
    query = query + " email: " + JSON.stringify(account.email);
  }
  if ("password" in account) {
    query = query + " password: " + JSON.stringify(account.password);
  }
  if ("is_subscribed" in account) {
    query = query + " is_subscribed: " + account.is_subscribed;
  }
  if (account.isCompany) {
    query = query + " company: " + JSON.stringify(account.company);
  }
  query = query + "}";
  query = query + ") { customer { email firstname lastname is_subscribed } } }";
  const retval = await axios({
    url: config.shop.graphQLURL,
    method: "POST",
    headers: headers,
    data: { query: query },
  }).catch((e) => {
    Logger.error("createAccount", "data-resolver user", e)();
    throw e;
  });
  if (retval.data.errors != null) {
    retval.data.errors.forEach((element) => {
      const msg = {
        type: "danger",
        title: "register_error",
        text: element.message,
      };
      store.dispatch("messages/sendMessage", { message: msg });
    });
  }
  // console.log("create account", retval.data);
  if (retval.data.data.createCustomerV2 != null) {
    return retval.data.data.createCustomerV2;
  } else {
    return false;
  }
}

const actions = {
  async createAccountV2({ commit }, { account }) {
    commit("setFake", null);
    const retval = await createAccountV2(account);

    if (retval != false) {
      commit("setUserToken", retval);
      commit("setIsLoggedIn", true);
      commit("setIsApproved", false);
      const me = await getCurrent();
      if (me != false) {
        let wl = me.wishlist;
        if (wl == null) {
          wl = { id: null, items: [] };
        }
        commit("setWishlist", wl);
        commit("setCurrentUser", me);
        const newCartToken = await getCustomerCartToken();
        const oldCartToken = store.getters["cart/getCartServerToken"];
        if (oldCartToken != null) {
          await mergeCart(oldCartToken, newCartToken);
        }
        store.commit("cart/setServerToken", newCartToken);
        await store.dispatch("cart/loadCart");
      } else {
        commit("setUserToken", null);
        commit("setIsLoggedIn", false);
        commit("setIsApproved", false);
        commit("setCurrentUser", {});
      }
    } else {
      return false;
    }
    return true;
  },
}
export default {
  namespaced: true,
  actions
};
