import axios from "axios";
import config from "@config";
import { Logger } from "@storefront/core/lib/logger";
import { getCurrentLanguage } from "@storefront/core/i18n";
import { getProductsByFilter } from "@storefront/core/data-resolver/products";
import store from "@/store";

/**
 * get getBrands
 * @returns brands
 */
export async function getBrands() {
  const lang = getCurrentLanguage();
  //Needs to become variable based on language
  const storelang = config.languages[lang];
  const storeview = storelang["storeview"];

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + config.shop.accessToken,
    Store: storeview,
  };
  const query =
    "{ getBrandsAlphabet( filter: [{}], products: [{}] ) { items { attributeValue category { id url_key url_path } description image logo metaDescription metaKeywords metaTitle positionInSlider shortDescription showInSlider title urlKey } letter }}";
  const retval = await axios({
    url: config.shop.graphQLURL + "?query=" + encodeURIComponent(query),
    method: "GET",
    headers: headers,
  }).catch((e) => {
    Logger.error("getBrandsAlphabet", "data-resolver products", e)();
    throw e;
  });
  return retval.data.data.getBrandsAlphabet;
}

/**
 * post product review
 * @param {object} inp
 * @returns review or false
 */
export async function createProductReview(inp) {
  const lang = getCurrentLanguage();
  const storelang = config.languages[lang];
  const storeview = storelang["storeview"];
  let headers = {};
  if (store.getters["user/getIsLoggedIn"] != false) {
    headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + store.getters["user/getUserToken"],
      Store: storeview,
    };
  } else {
    headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + config.shop.consumerKey,
      Store: storeview,
    };
  }

  let query =
    "mutation {createProductReview (input: {" +
    'sku:"' +
    inp.sku +
    '" ' +
    " nickname: " +
    JSON.stringify(inp.nickname) +
    " summary: " +
    JSON.stringify(inp.summary) +
    " text: " +
    JSON.stringify(inp.text) +
    " ratings: [";
  inp.ratings.forEach((element) => {
    query =
      query +
      "{ id: " +
      JSON.stringify(element.id) +
      ", value_id: " +
      JSON.stringify(element.value_id) +
      "} ";
  });

  query =
    query +
    "]}){ review { nickname summary text average_rating ratings_breakdown { name value } } }}";
  const retval = await axios({
    url: config.shop.graphQLURL,
    method: "POST",
    headers: headers,
    data: { query: query },
  }).catch((e) => {
    Logger.error("createProductReview", "data-resolver carts", e)();
    console.log(e);
    throw e;
  });
  if (retval.data.data.createProductReview != null) {
    return retval.data.data.createProductReview;
  } else {
    if (retval.data.errors != null) {
      retval.data.errors.forEach((element) => {
        if (store.getters["user/getIsLoggedIn"] != false) {
          if (element.extensions.category === "graphql-authorization") {
            return store.dispatch("user/sessionExpired");
          }
        }
      });
    }
    return false;
  }
}

const state = () => ({
  serie: [],
  brands: [],
});

const actions = {
  /**
   * load Blog by Identifier
   * @param {json} identifier
   */
  async loadSerie({ commit }, { serie_id }) {
    const filter = 'serie:{eq:"' + serie_id + '"}';
    const page = await getProductsByFilter(filter).catch((e) => {
      Logger.error("load", "blog store actions", e)();
      throw e;
    });
    if (page != null) {
      commit("setSerie", page.items);
    }
  },

  async loadBrands({ commit }) {
    const brands = await getBrands().catch((e) => {
      Logger.error("getBrands", "product store actions load", e)();
      throw e;
    });
    if (!brands.length) {
      return false;
    } else {
      commit("setBrands", brands);
      return brands;
    }
  },

  async postReviews(_, { form }) {
    const products = await createProductReview(form).catch((e) => {
      Logger.error("addProductReview", "product store actions load", e)();
      throw e;
    });
    return products.review;
  },

  async getBlogsBySearchPreview(_, { filter, signal }) {
    const lang = getCurrentLanguage();
    const storelang = config.languages[lang];
    const storeview = storelang["storeview"];

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + config.shop.accessToken,
      Store: storeview,
    };
    const query =
      " { blogPosts(filter:" +
      ' { title: { like: "%' +
      filter +
      '%" } } ' +
      "pageSize: 5)" +
      "{ items { content title } total_count total_pages }}";
    const retval = await axios({
      url: config.shop.graphQLURL + "?query=" + encodeURI(query),
      method: "GET",
      headers: headers,
      signal: signal,
    }).catch((e) => {
      Logger.error("getBlogsBySearchPreview", "blogs", e)();
      throw e;
    });
    return retval.data.data.blogPosts;
  },

  async getCategoriesBySearchPreview(_, { filter, signal }) {
    const lang = getCurrentLanguage();
    const storelang = config.languages[lang];
    const storeview = storelang["storeview"];

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + config.shop.accessToken,
      Store: storeview,
    };
    Logger.debug('getCategoriesBySearchPreview', 'index', filter)();
    const query =
      "{categories(search:" +
      JSON.stringify(
        filter
          ?.replace(/\s+/g, " ")
          ?.trim()
          ?.toLowerCase() || ''
      ) +
      "){items{name url_key url_path level path image product_count children{name image description}}}}";
    const retval = await axios({
      url: config.shop.graphQLURL + "?query=" + encodeURI(query),
      method: "GET",
      headers: headers,
      signal: signal,
    }).catch((e) => {
      Logger.error("getBlogsBySearchPreview", "blogs", e)();
      throw e;
    });
    return retval.data.data.categories;
  },
};

const mutations = {
  /**
   * set list page
   * @param {object} state
   * @param {object} payload
   */
  setSerie(state, payload) {
    state.serie = payload;
  },

  /**
   * set the brand slider
   *
   * @param {array} data
   * @private
   */
  setBrands(state, data) {
    state.brands = data;
  },
};

const getters = {
  getSerie: (state) => state.serie,
  getBrands: (state) => state.brands,
};

// export this module.
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
